/* eslint-disable react-hooks/exhaustive-deps */
import { XYCoord, useDrag, useDrop } from "react-dnd";
import update from "immutability-helper";
import FareStructureTable from "components/lfa/LovestFareCalendar/FareStructureTable";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

interface BoxProps {
  id: any;
  left: number;
  top: number;
  hideSourceOnDrag?: boolean;
  children?: ReactNode;
}
interface DragItem {
  type: string;
  id: string;
  top: number;
  left: number;
}

export const DragAndDropComponent = ({
  setVisible,
  selectedDate,
  cabin_code,
}) => {
  const [boxes, setBoxes] = useState<{
    [key: string]: {
      top: number;
      left: number;
      title: any;
    };
  }>({
    a: {
      top: 20,
      left: 80,
      title: (
        <FareStructureTable
          setVisible={setVisible}
          selectedDate={selectedDate}
          cabin_code={cabin_code}
        />
      ),
    },
  });

  useEffect(() => {
    setBoxes({
      a: {
        top: 20,
        left: 80,
        title: (
          <FareStructureTable
            setVisible={setVisible}
            selectedDate={selectedDate}
            cabin_code={cabin_code}
          />
        ),
      },
    });
  }, [selectedDate, cabin_code]);

  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes, setBoxes]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );
  return (
    <div
      ref={drop}
      style={{
        width: 1080,
        height: 1080,
        position: "relative",
      }}
    >
      {Object.keys(boxes)?.map((key) => {
        const { left, top, title } = boxes[key] as {
          top: number;
          left: number;
          title: any;
        };
        return (
          <Box key={key} id={key} left={left} top={top}>
            {title}
          </Box>
        );
      })}
    </div>
  );
};

const Box: FC<BoxProps> = ({ id, left, top, hideSourceOnDrag, children }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "box",
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }
  return (
    <div
      className="box"
      ref={drag}
      style={{ left, top, position: "absolute", cursor: "move" }}
      data-testid="box"
    >
      {children}
    </div>
  );
};
